<template>
  <div class="homepage">
    <v-container
      fluid
      class="category-block-header"
      :class="{
        'd-none': $ebsn.meta(category, 'metaData.category_info.HIDE_TITLE')
      }"
    >
      <category-title :category="category" />
    </v-container>

    <category-block
      v-for="config in category.categoryBlocks"
      :key="config.categoryBlockId"
      :config="config"
      class="category-block"
      :class="`category-block-${config.templateBlock.codInt}`"
    />

    <ebsn-meta
      :target="category"
      path="category_info.FOOTER_DESCRIPTION"
      tag="div"
      class="category-block-footer"
    />
  </div>
</template>

<style lang="scss"></style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import category from "~/mixins/category";
import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";

import { mapActions } from "vuex";
import split from "lodash/split";

export default {
  name: "Home",
  mixins: [category, deliveryReactive, login],
  components: {
    CategoryTitle
  },
  data() {
    return {
      key: 1
    };
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      resetFilters: "category/resetFilters"
    }),

    async reload() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });

      this.key += 1;
    }
  },
  mounted() {
    global.EventBus.$on("login", () => {
      if (this.$vuetify.breakpoint.xs) {
        this.$vuetify.goTo(0, { duration: 0 });
      }
    });
  }
};
</script>
